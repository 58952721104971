@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Poppins:wght@700&display=swap");

body {
	font-family: "IBM Plex Mono" !important;
	background-color: white;
	margin: 0;
}

h2 {
	text-align: left;
	margin-bottom: 1em;
	margin-top: 0;
}

.container {
	position: relative;
	height: 100vh;
}

.pass-guard-container {
	width: 100%;
	display: flex;
	position: absolute;
	top: 70px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: calc(100% - 110px);
}

.pool-and-stake-withdraw-wrapper {
	display: flex;
	height: calc(100% - 110px);
	width: 100%;
	position: absolute;
	top: 70px;
	justify-content: center;
	align-items: center;
}

.pool-and-stake-withdraw-container {
	display: flex;
	align-items: flex-end;

	p {
		color: black;
		font-family: "IBM Plex Mono", monospace;
		margin: 0;
	}

	h1 {
		font-family: "IBM Plex Mono", monospace;
		color: black;
		font-size: 20px;
		line-height: 0.8;
	}
}

button,
.credix-button {
	font-family: "IBM Plex Mono", monospace !important;
	border: 1px solid black !important;
	background: white !important;
	font-weight: bold !important;
	height: 30px !important;
}

.sc-gsDKAQ {
	svg {
		width: 18px !important;
		height: 18px !important;
		top: 5px !important;
	}
}

.credix-button {
	font-size: 12px !important;
	padding: 3px 16px !important;
}

li > button {
	border: 0px !important;
	border-radius: 0px !important;
}

button > span,
.credix-button {
	color: black !important;
}

.MuiDialog-container {
	font-family: "IBM Plex Mono", monospace !important;
}

.MuiDialogTitle-root {
	background: white !important;
}

.makeStyles-root-5 .MuiDialogTitle-root {
	background: white !important;
}

.makeStyles-root-5 .MuiDialog-paper {
	padding: 15px;
}

.makeStyles-root-5 .MuiDialogContent-root .MuiListItem-root .MuiButton-root:hover {
	background: #e5e5e5 !important;
}

.MuiTypography-h6 {
	font-family: "IBM Plex Mono", monospace !important;
	margin-bottom: 20px !important;
}

.makeStyles-root-7 .MuiDialogTitle-root {
	background-color: white !important;
}

.makeStyles-root-6 .MuiDialogTitle-root {
	background-color: white !important;
}

button {
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
		0px 1px 5px 0px rgb(0 0 0 / 12%) !important;

	&:hover {
		box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
			0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
	}

	&:disabled {
		opacity: 0.3 !important;
		cursor: not-allowed !important;
	}
}

.makeStyles-root-7 {
	width: 20px !important;
	height: 20px !important;
}

.MuiButton-root {
	padding: 20px 40px;
}

.MuiButtonBase-root.MuiIconButton-root {
	width: 30px;
}

.container-initialize {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;

	button {
		margin-right: 20px;
	}
}

.container-deals {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
}

@media only screen and (max-width: 1124px) {
	.pool-and-stake-withdraw-container {
		flex-direction: column;

		.pool-container {
			margin: 0;
			margin-bottom: 10vh;
		}
	}
}
