.deal-container {
	width: 50vw;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}

.borrower-pk {
	text-transform: none !important; 
}

.deal-info-repayment {
	display: flex; 
	//align-items: flex-end;
	flex-direction: row; 
	width: 700px; 

	input {
		width: 300px; 
		margin-bottom: 30px; 
	}

	.repayment-select {
		width: 300px; 
		margin-bottom: 30px; 
	}

	.stake-submit {
		margin-top: 52px;
	}
}

.deal-info {
	width: 350px; 
	opacity: 0.5; 
	border-right: 1px solid black; 
}

.deal-input {
	width: 200px;
}