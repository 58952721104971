.MuiTableCell-root {
	font-family: "IBM Plex Mono" !important;
}

th.MuiTableCell-root {
	font-weight: bold;
}

.deal-name {
	max-width: 250px; 
	display: block;
	word-wrap:break-word;
}