.navbar-container {
	height: 70px;
	width: 100%;
	border-bottom: 1px solid lightgrey;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: 0;
	z-index: 1000;
}

.logo-and-tag-line {
	width: 200px;
	display: flex;
	align-items: flex-end;
}

.tag-line {
	display: inline-block;
	font-size: 12px;
	max-width: 200px;
	margin-left: 20px;
}

.header-button {
	margin-right: 20px !important;
}

.balance-wallet-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.balance-and-pk {
	margin-left: 20px;
	margin-right: 50px;

	h1 {
		font-size: 20px;
		margin: 0;
	}

	p {
		color: grey;
		font-size: 10px;
		margin-top: 3px;
	}
}

img.logo {
	max-height: 40px;
	margin-left: 20px;
}
