.stake-and-withdraw-container {
	position: relative; 
	width: 400px;
	margin-left: 3vw; 
	margin-bottom: 38px; 
}

.repayment-select {
	font-family: "IBM Plex Mono", monospace !important;
	border: 1px solid black !important;
	background: white !important;
	font-weight: bold !important;
	font-size: 12px !important;
	padding: 3px 16px !important;
	height: 30px !important;
	color: black !important;
	width: 100%;
	padding: 20px 20px;
	align-items: center;

	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
		0px 1px 5px 0px rgb(0 0 0 / 12%) !important;

	&:hover {
		box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
			0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
	}

	&:hover {
		border: 1px solid black !important;
		outline: none;
	}

	&:active {
		border: 1px solid black !important;
		outline: none;
	}

	&:disabled {
		opacity: 0.3 !important;
		cursor: not-allowed !important;
	}

	&:disabled {
		opacity: 0.3 !important;
		cursor: default;
		pointer-events: none;
	}

	&:focus-visible {
		border: 1px solid black !important;
		outline: none;
	}

	.MuiSelect-select {
		padding: 0 !important;
		text-align: center;
	}

	fieldset {
		border: none !important;
	}
}

.stake-input-label {
	.repayment-select {
		margin-top: 5px;
	}
}
