$pool-box-size: 30vw;

.hover-text {
	max-width: calc(#{$pool-box-size} / 2 - 2px);
	height: calc(#{$pool-box-size} / 2 - 2px);
	z-index: 1;
	opacity: 0;
	background: black;
	margin: 0 !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	p {
		color: white !important;
		font-size: 12px;
		text-align: center;
		padding: 10% !important;
	}

	&:hover {
		opacity: 100;
	}
}

.pool-container {
	margin-right: 1vw;
}

.pool-box {
	width: calc(3 * (#{$pool-box-size} / 2 - 1px));
	display: flex;
	position: relative;
	border: 1px solid grey;

	p {
		padding-bottom: 10px !important;
	}

	hr {
		margin: 30px 0;
	}
}

.pool-stat {
	width: calc(#{$pool-box-size} / 2 - 1px);
	height: calc(#{$pool-box-size} / 2 - 1px);
	border: 1px solid grey;
	display: flex;
	flex-direction: column;
	position: relative;
}

.pool-stat-title {
	position: absolute;
	font-size: 12px;
	right: 10px;
	bottom: 0;
	text-align: right;
}

.pool-stat-number {
	text-align: center;
	flex-grow: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 40px;
	font-weight: bold;
}

.refresh-stats {
	margin-bottom: 1em !important;
}

@media only screen and (max-width: 1124px) {
	$pool-box-size: 400px;
	.hover-text {
		max-width: calc(#{$pool-box-size} / 2 - 2px);
		height: calc(#{$pool-box-size} / 2 - 2px);
	}

	.pool-box {
		width: calc(3 * (#{$pool-box-size} / 2 - 1px));
	}

	.pool-stat {
		width: calc(#{$pool-box-size} / 2 - 1px);
		height: calc(#{$pool-box-size} / 2 - 1px);
	}
}

.credix-apy {
	font-size: 14px; 
	color: green !important; 
}