.stake-input-label {
	flex-grow: 2;
	margin-right: 10px;

	p {
		font-size: 12px; 
	}
}

.stake-input {
	width: 100%;

	&:disabled {
		opacity: 0.3 !important;
		cursor: default;
		pointer-events: none;
	}
}

.stake-submit {
	cursor: pointer !important;
	margin-left: 10px;
	pointer-events: auto !important;
	width: 150px;
	&:disabled {
		opacity: 0.3 !important;
		cursor: not-allowed !important;
	}
}

.row {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	flex-wrap: wrap;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.deposit-withdraw-row {
	position: relative; 
}

.max-button {
	position: absolute; 
	z-index: 3;
	bottom: 2px; 
	font-size: 12px; 
	left: 214px; 
	width: 30px; 
	align-self: flex-end; 
	margin-right: 10px; 
	cursor: pointer; 
}

.stake-form-column {
	flex-direction: column;
	align-items: flex-start;
	width: 600px;
	border: 1px solid black;
	padding: 30px;
	margin-top: 0;

	.stake-input-label {
		margin-bottom: 10px;
		width: 100% !important;

		input {
			margin-top: 5px;
		}
	}

	.stake-submit: {
		margin-left: 0px !important;
	}
}
