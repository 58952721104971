.help-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 70px);
	width: 100vw;
	bottom: 0;
	position: absolute;
}

.help-wrapper {
	border: 1px solid black;
	padding: 40px;
	padding-top: 10px;

	p {
		max-width: 400px;
		font-size: 12px;
	}
	a {
		color: black;
	}

	h3 {
		margin-bottom: 20px;
		margin-top: 30px;
	}
}
