.credix-pass-containter {
	width: 50vw;
	margin: 1% auto 0 auto;
	display: flex;
	justify-content: space-between;
}

.credixpass-view-switch {
	width: 50vw;
	margin: 7% auto 0 auto;
	display: block;

	button {
		margin-right: 20px; 
	}
}
